<template>
  <div>
    <z-header></z-header>
    <div class="min_width banner_image_box">
      <img src="../assets/img_banner_a.png" alt="" />
    </div>
    <div class="solution">
      <div class="page_center solution_float">
        <div class="page_title">解决方案 · SOLUTION</div>
        <div class="solution_box">
          <div class="solution_item">
            <img src="../assets/ic_service_g.png" alt="" />
            <span>品牌定制网站</span>
          </div>
          <div class="solution_item">
            <img src="../assets/ic_service_g.png" alt="" />
            <span>品牌定制网站</span>
          </div>
          <div class="solution_item">
            <img src="../assets/ic_service_g.png" alt="" />
            <span>品牌定制网站</span>
          </div>
          <div class="solution_item">
            <img src="../assets/ic_service_g.png" alt="" />
            <span>品牌定制网站</span>
          </div>
        </div>
      </div>
      <div class="page_center card_box">
        <img class="card" src="../assets/img_culture_h.png" alt="" />
        <div class="card card_def">
          <p class="title">高端设计团队</p>
          <p>
            用户体验，市场潮流，大众趋势从这样的角度才能获得优质的网站设计效果
          </p>
        </div>
      </div>
    </div>
    <div class="min_width section_box backstage_box">
      <div class="page_center content">
        <div class="page_title">后台管理 · BACKSTAGE MANAGEMENT</div>
        <p class="desc">
          腾创互联为每一名用户管理爱站更加快捷、清爽简洁的页面、微交互、爱站更新、发布新闻、发布图片、能够实现一键发布、高效率管理网站
        </p>
        <div class="list_box">
          <div class="item">
            <span class="order_icon order1"></span>
            <span>客户流量</span>
            <span>今日波动</span>
          </div>
          <div class="item">
            <span class="order_icon order2"></span>
            <span>人员查看</span>
            <span>人员缺迟</span>
            <span>人员变动</span>
          </div>
          <div class="item">
            <span class="order_icon order3"></span>
            <span>新闻更新</span>
            <span>上传图片</span>
          </div>
          <div class="item">
            <span class="order_icon order4"></span>
            <span>一键更新</span>
          </div>
        </div>
      </div>
    </div>
    <div class="page_center section_box success_case">
      <div class="page_title">成功案例 · SUCCESS CASE</div>
      <div class="swiper_case_box">
        <swiper class="swiper_case" :options="caseSwiperOption1">
          <swiper-slide v-for="(item, index) of caseList" :key="index">
            <router-link :to="'/caseDetails/' + item.objId">
              <img class="swiper_case_img" :src="item.headImg" />
            </router-link>
          </swiper-slide>
        </swiper>
        <!-- 如果需要导航按钮 -->
        <div class="case-swiper-button case-swiper-button-prev"></div>
        <div class="case-swiper-button case-swiper-button-next"></div>
      </div>
    </div>
    <z-footer></z-footer>
  </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
export default {
  components: {
    swiper,
    swiperSlide,
  },
  data() {
    return {
      caseSwiperOption1: {
        slidesPerView: 3,
        // centeredSlides: true,
        spaceBetween: 20,
        navigation: {
          nextEl: ".case-swiper-button-next",
          prevEl: ".case-swiper-button-prev",
        },
      },

      caseList: [],
    };
  },
  created() {
    this.getCaseList();
  },
  methods: {
    // 获取案例
    getCaseList() {
      // 案例
      let httpData = {
        pageNo: 1,
        pageSize: 12,
      };
      this.$http.post("api/case/case_main", httpData).then((res) => {
        this.caseList = res.data.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.section_box {
  padding-top: 80px;
}

.page_title {
  font-size: 32px;
  color: #333333;
  text-align: center;
  &.white {
    color: #fff;
  }
}

.banner_image_box {
  height: 800px;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.solution {
  position: relative;
  height: 625px;
  padding-top: 165px;

  .solution_float {
    position: absolute;
    top: -200px;
    left: 50%;
    transform: translateX(-50%);
    height: 285px;
    padding-top: 40px;
    background-color: #ffffff;
    box-shadow: 0px 12px 30px 0px rgba(105, 61, 210, 0.16);
    border-radius: 20px;

    .solution_box {
      display: flex;
      justify-content: space-around;
      margin-top: 40px;
      color: #333333;

      .solution_item {
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
          width: 80px;
          height: 80px;
          margin-bottom: 13px;
        }
      }
    }
  }

  .card_box {
    position: relative;
    .card {
      width: 720px;
      height: 300px;
      color: #fff;
    }

    .card_def {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 50%;
      top: 80px;
      background-color: #6f40f3;
      border-radius: 0px 20px 20px 20px;
      text-align: center;

      .title {
        margin-bottom: 40px;
        font-size: 32px;
      }
    }
  }
}

.backstage_box {
  height: 547px;
  // background: url("../assets/bg_b.png") no-repeat center;
  background-image: url("../assets/bg_b.png");
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  object-fit: cover;
  .content {
    color: #333333;
  }

  .page_title {
    width: 587px;
  }

  .desc {
    width: 340px;
    margin: 60px 0;
  }

  .list_box {
    .item {
      display: flex;
      align-items: center;
      height: 37px;
      margin-bottom: 12px;

      span {
        margin-right: 24px;
      }
    }

    .order_icon {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 30px;
      margin-right: 30px;
      background: #ffcd2a;
      border-radius: 8px;
      transform-origin: center;
      transform: rotate(45deg);
      box-shadow: 7px -7px 0 -3px #ff9c2a;

      &::before {
        content: "";
        transform-origin: center;
        transform: rotate(-45deg);
        color: #fff;
        font-size: 12px;
        font-weight: 600;
        line-height: 30px;
      }

      &.order1::before {
        content: "01";
      }
      &.order2::before {
        content: "02";
      }
      &.order3::before {
        content: "03";
      }
      &.order4::before {
        content: "04";
      }
    }
  }
}

.success_case {
  height: 546px;
  .swiper_case_box {
    margin-top: 70px;
    .swiper_case{
      padding-top: 10px;
    }
    
    .swiper_case_img {
      width: 396px;
      transition: transform 0.4s;
      &:hover {
        box-shadow: 0px 1em 2em 0px rgba(48, 55, 66, 0.15);
        transform: translate(0, -4px);
      }
    }
  }
}

.swiper_case_box {
  margin-top: 40px;
  height: 264px;
  position: relative;
  .swiper_case_img {
    width: 100%;
    height: 264px;
    border-radius: 20px;
    cursor: pointer;
  }

  .case-swiper-button {
    position: absolute;
    top: 50%;
    margin-top: -20px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #222222;
    cursor: pointer;

    &.white {
      background: #fff;
    }

    &::after {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      width: 12px;
      height: 12px;
      background: transparent;
      border: 2px solid transparent;
    }
  }

  .case-swiper-button-prev {
    left: -100px;

    &::after {
      margin-left: 6px;
      transform-origin: center;
      transform: translate(-50%, -50%) rotate(45deg);
      border-left-color: #fff;
      border-bottom-color: #fff;
    }

    &.white::after {
      border-left-color: #222222;
      border-bottom-color: #222222;
    }
  }

  .case-swiper-button-next {
    right: -100px;

    &::after {
      margin-left: -6px;
      transform-origin: center;
      transform: translate(-50%, -50%) rotate(-45deg);
      border-right-color: #fff;
      border-bottom-color: #fff;
    }

    &.white::after {
      border-right-color: #222222;
      border-bottom-color: #222222;
    }
  }
}
</style>